<template>
  <div class="flex flex-col items-start w-full sm:w-10/12 mx-auto my-4 border shadow-sm cursor-pointer rounded-lg"
       :class="{
        'bg-blue-custom text-white': isSelected && !isCorrectAnswer && !isWrongAnswer,
        'bg-white': !isSelected,
        'bg-[#10CB66] text-white': isCorrectAnswer,
        'bg-white text-answer-error  border-answer-error': isWrongAnswer,

      }" @click="handleClick">

    <div class="flex flex-row gap-2 py-2 px-4 rounded-lg w-full items-center">
      <span v-if="$isNotEmpty(answerLabel)" class="bg-circle-gray rounded-full p-2 text-black font-bold w-7 h-7 flex items-center justify-center"
            :class="{
        'circle-answer-error': isWrongAnswer,
      }"

      >{{answerLabel }}</span>
      <span v-if="isTextAnswer || isNumericAnswer" v-html="answerBody"></span>
      <div v-if="isTextAndPhotoAnswer">
        <p v-if="$isNotEmpty(answerBody)" v-html="answerBody"></p>
        <img v-if="$isNotEmpty(answerPhoto)" :src="answerPhoto">
      </div>
    </div>

  </div>
</template>

<script>
import answerDataMixin from "@/mixins/answerData";

export default {
  mixins: [answerDataMixin],
  props: {
    answer: {
      type: Object,
      required: true,
    },
    selectedAnswer: {
      type: Object,
      default: null,
    },
    isCorrect: {
      type: Boolean,
      default: false,
    },
    isAnsweredAlreadyChecked: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isSelected() {
      return this.$isNotEmpty(this.selectedAnswer) && this.selectedAnswer.id === this.answerId;
    },
    isCorrectAnswer(){
      return Boolean(this.isSelected && this.isCorrect && this.isAnsweredAlreadyChecked) ;
    },
    isWrongAnswer(){
      return Boolean(this.isSelected && !this.isCorrect && this.isAnsweredAlreadyChecked);
    },
  },
  methods: {
    handleClick() {
      this.$emit('select', this.answerId);
    },
  },
};
</script>

<style scoped>
.text-answer-error{
  color: #EC1212 !important;
}

.border-answer-error{
  border: 1px solid #EC1212 !important;
}

.circle-answer-error{
  background-color: #EC1212 !important;
  color: white !important;
}
</style>
