<template>
  <div class="flex relative md:m-[17px] mb-[26px] ">
    <LoadingSpinner :is-loading="isLoading"/>

    <span @click="onClickPay"
          class="h-[35px] cursor-pointer relative w-[265px] text-xs md:text-[17px] flex items-center justify-center bg-[#25D366] text-[#fff] rounded-[15px] md:rounded-[33px] ">
        <span
            class="rounded-full absolute right-[2px] md:right-[4px] bg-white w-[31px] md:w-[31px] h-[31px] md:h-[31px] flex justify-center items-center">
          <img class="cursor-pointer rounded-full md:m-auto w-[32px] h-[32px] "
               :src="require('@images/whatsappIcon.png')" alt="whatsapp">
        </span> 
        <span class="mr-4 text-[17px]">اضغط هنا لدفع الاشتراك</span>


    </span>

    <div v-if="checkoutErrors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
              <span v-for="(error, index) in checkoutErrors" :key="index">
                {{ error }}
              </span>
    </div>

  </div>
</template>

<script>
import {checkout} from "@/graphql/mutations/cart/handlingCalls";
import routes from "@/router/routes";

export default {
  name: 'PaymentHere',
  props: {
    paymentId: {
      type: [String, Number]
    },
  },
  data() {
    return {
      checkoutErrors: [],
      isLoading: false
    }
  },
  methods: {
    async onClickPay() {
      this.checkoutErrors = [];

      try {
        const checkoutResponse = await this.$withLoading(() => checkout(this.paymentId));

        if (!checkoutResponse.success) {
          this.checkoutErrors = checkoutResponse.displayValidationMessages;
          return;
        }

        if (checkoutResponse.data?.is_cart_free) {
          this.$router.push({ name: 'studyContent' });
          return;
        }

        window.open(checkoutResponse.data?.redirect_url, '_blank');
        routes.navigateTo(routes.HOME.key);
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    }
  },
}

</script>